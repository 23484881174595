import React from 'react';
import { useLocation } from 'react-router-dom';

const BreadcrumbSchema = () => {
    const location = useLocation();
    const paths = location.pathname.split('/').filter(Boolean);

    const breadcrumbItems = paths.map((path, index) => ({
        "@type": "ListItem",
        "position": index + 1,
        "name": path.charAt(0).toUpperCase() + path.slice(1),
        "item": `${window.location.origin}/${paths.slice(0, index + 1).join('/')}`,
    }));

    const breadcrumbSchema = {
        "@context": "https://schema.org",
        "@type": "BreadcrumbList",
        "itemListElement": [
            {
                "@type": "ListItem",
                "position": 1,
                "name": "Home",
                "item": window.location.origin,
            },
            ...breadcrumbItems,
        ],
    };

    return (
        <script type="application/ld+json">
            {JSON.stringify(breadcrumbSchema)}
        </script>
    );
};

export default BreadcrumbSchema;
